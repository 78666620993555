// extracted by mini-css-extract-plugin
export var backgroundoatmeal = "PageManager-module--backgroundoatmeal--48f5e";
export var backgroundsandyfur = "PageManager-module--backgroundsandyfur--d91b6";
export var backgroundsunshineyellow = "PageManager-module--backgroundsunshineyellow--17873";
export var backgroundwhite = "PageManager-module--backgroundwhite--ae086";
export var border = "PageManager-module--border--8f953";
export var borderbottom = "PageManager-module--borderbottom--52c74";
export var borderbottomimagecontainer = "PageManager-module--borderbottomimagecontainer--c3500";
export var borderimage = "PageManager-module--borderimage--b1f45";
export var bordersectionbottom = "PageManager-module--bordersectionbottom--2b930";
export var bordersectionbottomwithmargin = "PageManager-module--bordersectionbottomwithmargin--43ebb PageManager-module--bordersectionbottom--2b930";
export var borderwithimage = "PageManager-module--borderwithimage--545e2";
export var borderwithimagewrapper = "PageManager-module--borderwithimagewrapper--d82d4";
export var borderwithimagewrapperwithmargin = "PageManager-module--borderwithimagewrapperwithmargin--d9665";
export var borderwrapper = "PageManager-module--borderwrapper--445db";
export var defaultgridwidth = "PageManager-module--defaultgridwidth--f5b39 PageManager-module--gridspacing--c844e";
export var defaultsectionspacing = "PageManager-module--defaultsectionspacing--b04d2 PageManager-module--gridspacing--c844e";
export var extratopmarginsectionspacing = "PageManager-module--extratopmarginsectionspacing--c17d3 PageManager-module--gridspacing--c844e";
export var fullgridwidth = "PageManager-module--fullgridwidth--ec728 PageManager-module--gridspacing--c844e";
export var gridspacing = "PageManager-module--gridspacing--c844e";
export var hidden = "PageManager-module--hidden--36a29";
export var hideondesktop = "PageManager-module--hideondesktop--c0071";
export var higherindex = "PageManager-module--higherindex--24416";
export var imagewrapper = "PageManager-module--imagewrapper--d5164";
export var sectionbreak = "PageManager-module--sectionbreak--c323d";
export var sectionbreakdoublemargin = "PageManager-module--sectionbreakdoublemargin--4db75";
export var sectioncolourband = "PageManager-module--sectioncolourband--b8fe6";
export var sectionnospacing = "PageManager-module--sectionnospacing--c1346";
export var sectionnospacingbottom = "PageManager-module--sectionnospacingbottom--dcf53";
export var sectionnospacingleftright = "PageManager-module--sectionnospacingleftright--9ee7c PageManager-module--defaultsectionspacing--b04d2 PageManager-module--gridspacing--c844e";
export var sectionspacinghomequicklinks = "PageManager-module--sectionspacinghomequicklinks--892be";
export var sectionspacingxxl = "PageManager-module--sectionspacingxxl--b872c PageManager-module--gridspacing--c844e";
export var sectionwrapper = "PageManager-module--sectionwrapper--374f4";
export var sectionwrapperalt = "PageManager-module--sectionwrapperalt--5ef70 PageManager-module--sectionwrapper--374f4";
export var sectionwrapperlight = "PageManager-module--sectionwrapperlight--fdcc5 PageManager-module--sectionwrapper--374f4";
export var sectionwrapperoat = "PageManager-module--sectionwrapperoat--390d4 PageManager-module--sectionwrapper--374f4";
export var sectionwrapperprimary = "PageManager-module--sectionwrapperprimary--00072 PageManager-module--sectionwrapper--374f4";
export var xlargegridwidth = "PageManager-module--xlargegridwidth--f64b1 PageManager-module--gridspacing--c844e";
export var xxlargegridwidth = "PageManager-module--xxlargegridwidth--496c7 PageManager-module--gridspacing--c844e";
export var xxlargegridwidthnopadding = "PageManager-module--xxlargegridwidthnopadding--8f21b PageManager-module--gridspacing--c844e";