// extracted by mini-css-extract-plugin
export var errormessage = "FormTextInputAutoSuggest-module--errormessage--4c050";
export var formtextinput = "FormTextInputAutoSuggest-module--formtextinput--3c598";
export var formtextinputwithlefticon = "FormTextInputAutoSuggest-module--formtextinputwithlefticon--3d870";
export var iconleft = "FormTextInputAutoSuggest-module--iconleft--f672a";
export var iconright = "FormTextInputAutoSuggest-module--iconright--e7520";
export var inputwithicon = "FormTextInputAutoSuggest-module--inputwithicon--db884";
export var suggestion = "FormTextInputAutoSuggest-module--suggestion--855f9";
export var suggestionbox = "FormTextInputAutoSuggest-module--suggestionbox--3499e";
export var suggestionboxattribution = "FormTextInputAutoSuggest-module--suggestionboxattribution--49ef6";
export var suggestionboxresults = "FormTextInputAutoSuggest-module--suggestionboxresults--da323";
export var textinputlabel = "FormTextInputAutoSuggest-module--textinputlabel--50fec";
export var textinputwrapper = "FormTextInputAutoSuggest-module--textinputwrapper--03279";